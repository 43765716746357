import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "The pain you feel today will be the strength you feel tomorrow.",
    name: 'SURESH RATHOD',
    status : 'TRAINER'
  },
  {
    image: image2,
    review: 'The only way to achieve your goals is to shut out the noise and focus on the work ',
    name: 'HARISH REDKAR',
    status: 'TRAINER'
  },
  {
    image : image3,
    review:' The pain of discipline is nothing like the pain of disappointment',
    name: 'DNYANESHWAR PARAB',
    status: "TRAINER"
  }
];
