import React from 'react'
import './Join.css'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import { useAuth0 } from "@auth0/auth0-react";
import swal from 'sweetalert'
export const Join = () => {
    const form = useRef()
    const handle = ()=>{
        swal({
            title: "Submitted successfully",
            text:"Our Team will get back to you through email",
            icon: "success",
            button: "ok",
          });
    }
    const { user, isAuthenticated} = useAuth0();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_ib0vfhv', 'template_u742ly9', form.current, 'lDYycDrOpbub71Wky')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className="Join" id="join-us">
        <div className="left-j">
            <hr />
            <div>
                <span className='stroke-text'>READY TO</span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className='stroke-text'>WITH US?</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className="email-container" onSubmit={sendEmail}>
            <label className='plan-label'>
          Select Plan:
          <select name='selected_plan'>
            <option value="basic">Basic</option>
            <option value="premium">Premium</option>
            <option value="pro">Pro</option>
            
          </select>
        </label>
                <input type="email" value={isAuthenticated ? user.email : ''} name='user_email' placeholder='Enter Your Email' />
                <button className='btn btn-j' onClick={handle}>Join Now</button>
            </form>
        </div>
    </div>
  )
}
