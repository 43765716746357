import React from 'react'
import './Footer.css'
import Instagram from '../../assets/instagram.png'
import logo from '../../assets/logo.png'
export const Footer = () => {
  return (
    <div className="Footer-container"id='footer'>
        <hr />
        <div className="footer">
            
            <div className="social-links">
                <a href="https://www.instagram.com/bullrockfitnessclub" target='_blank'>
                    <img  src={Instagram} alt="" />
                </a>
           </div>
           <div className="logo-f">
                <img src={logo} alt="" />
            </div>
        </div>
        <div className="blur blur-f-1"></div>
        <div className="blur blur-f-2"></div>
    </div>
  )
}
